export const getQueryStringByUrl = function () {
  // http://localhost:9090/?code=001ePyml2W5bLd4sFvml2rIv4B4ePyme&state=Home#/redirect?cardId=18075089&appId=wxec8cdec35008011a&orgId=100001781&subCardType=1
  // http://localhost:9090/?code=001ePyml2W5bLd4sFvml2rIv4B4ePyme&state=Home#/redirect?cardId=18075089&appId=wxec8cdec35008011a&orgId=100001781&response_type=code&scope=snsapi_userinfo&state=Home&connect_redirect=1#wechat_redirect
  //http://localhost:9090/?code=001ePyml2W5bLd4sFvml2rIv4B4ePyme&state=Home#/user?cardNo=1000241171&serialNo=REmmPd
  // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxec8cdec35008011a&redirect_uri=http%3A%2F%2Fcard.lerke.cn%2F%23%2Fredirect%3FcardId%3D%26appId%3Dwxec8cdec35008011a%26orgId%3D100001781&response_type=code&scope=snsapi_userinfo&state=Home&connect_redirect=1#wechat_redirect

  let query = {};
  let href = location.href;
  console.log('=====herf======', href);
  let urls = href.split('#'); // 路由模式为hash
  // console.log('urls=====>', urls);
  urls.forEach((url) => {
    let querystr = url.split('?')[1];
    if (!querystr) return;
    let querys = querystr.split('&') || [];
    // console.log('querys=====>', querys);
    querys.forEach((_q) => {
      try {
        let keys = _q.split('=');
        query[keys[0]] = decodeURIComponent(keys[1]);
      } catch (error) {
        console.log('error');
      }
    });
  });
  console.log('query==========', query);
  return new Promise((resolve, reject) => {
    if (query) {
      resolve(query);
    } else {
      reject(false);
    }
  });
};
